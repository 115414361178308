import wireframeContact from "../assets/wirefames/wireframe-contact.svg";
import servicewireframe from "../assets/wirefames/serviceswireframe.svg";
import servicecircle from "../assets/wirefames/servicecircle.svg";
import servicearrow from "../assets/wirefames/servicearrow.svg";
import blueline from "../assets/wirefames/blueLine.svg";
import yellowline from "../assets/wirefames/yellowLine.svg";
import servicetriangle from "../assets/wirefames/servicetriangle.svg";
import aboutstar from "../assets/wirefames/aboutstar.svg";
import servicetoptriangle from "../assets/wirefames/servicetoptriangle.svg";
import aboutwhitecircle from "../assets/wirefames/aboutwhitecircle.svg";
import abouthalfcircle from "../assets/wirefames/abouthalfcircle.svg";

const wireframes = {
  wireframeContact,
  servicearrow,
  servicetoptriangle,
  servicewireframe,
  servicecircle,
  blueline,
  yellowline,
  servicetriangle,
  aboutwhitecircle,
  aboutstar,
  abouthalfcircle
};
export default wireframes;
