import { useState } from "react";
// import { WIREFRAMES } from "../../constants";


const ContactView = () => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
  
    formData.append("access_key", "2ee9bd1b-4caa-4466-b6e9-557fbff3ce15");
  
    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);
  
    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: json,
    }).then((res) => res.json());
  
    if (res.success) {
      console.log("Success", res);
    }
    setShowSuccessMessage(true);
  
    setTimeout(() => {
      setShowSuccessMessage(false);
      // Reset the form after successful submission
      event.target.reset();
    }, 3000);
  };
  

  const SuccessMessage = ({ visible }) => (
    <div
      className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#FFB422] text-black px-4 py-2 rounded shadow-md ${
        visible ? "block" : "hidden"
      }`}
    >
      Form submitted successfully!
    </div>
  );

  return (
    <div className="bg-[#082757] ">
      <div className="w-[100%] flex justify-center items-center text-center  lg:py-[25rem] md:py-[18rem] py-[23rem]">
        <div className="grid ">
          <div className="flex flex-col justify-center items-center">
            <div class="text-white  text-[20px] md:text-[50px] lg:text-[60px] lg:max-2xl:text-[35px]  font-grotesk font-bold ">
              Get in Touch
            </div>
            <div className="flex flex-col justify-center items-center md:flex-row lg:flex-row">
              <p
                className=" flex px-3 leading-8 text-[#F9FAFB] text-[16px] md:text-[20px]  lg:text-[19px] font-grotesk pt-8
               font-normal text-center  mt-6 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-[33.1px] h-[28px] p-auto"
                >
                  <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                  <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                </svg>
                contact@ebonytechnology.co.uk
              </p>
              <p
                className=" flex px-3  leading-8 text-[#F9FAFB] text-[16px] md:text-[16px]  lg:text-[20px]  font-grotesk   pt-8
               font-normal text-center   mt-6"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                    clipRule="evenodd"
                  />
                </svg>
                +44 (0) 772 761 8504
              </p>
            </div>
          </div>
          <div class="flex ">
            {/* <img src={WIREFRAMES.wireframeContact} alt="" className='absolute opacity-30 right-[-0.01rem] w-[100px] md:w-[500px]
                         lg:w-[600px] lg:max-2xl:w-[480px] lg:2xs:w-[300px] lg:max-2xl:left-[860px] h-[421px] top-[400px] max-xsm:right-[-3.01rem] max-xsm:hidden'  /> */}
                        
            {/* <div className=''>
                            <img src={Linered} />
                            <img src={Lineblue} />
                        </div> */}
          </div>
        </div>
      </div>
      <section className="lg:px-[10%] md:px-[5%] max-xsm:px-[5%]  xssm:px-[5%] text-gray-700 body-font  h-[700px] bg-white py-7">
        <div className="  py-4 w-[90%] md:w-[90%] px-[2rem]  xssm:w-[90%] max-xsm:w-[90%] lg:w-[80%] lg:max-2xl:w-[80%]    bg-white shadow-2xl rounded-2xl absolute top-[700px] max-xsm:[600px]">
          <div className="flex flex-col w-12 mb-12 mx-12"></div>
          <form onSubmit={onSubmit}>
            <div className=" px-0 lg:px-9">
              <div className="block lg:flex sm:flex-rol lg:flex-wrap">
                <div className="p-3 w-2/2 lg:w-1/2">
                  <div className="relative">
                    <input
                      type="hidden"
                      name="access_key"
                      value="2ee9bd1b-4caa-4466-b6e9-557fbff3ce15"
                      className="w-full py-3 px-4 border-2 border-[#DCDCDC] rounded-[5px] focus:border-primary outline-none'"
                    />
                    <label
                      for="name"
                      className="leading-8 text-[20px] py-12 font-bold text-[#242331]"
                    >
                      Full Name *
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="John David"
                      className="w-full h-[50px] border-2 border-[#DCDCDC]  rounded-xl 
                    focus:border-[#DCDCDC] text-base 
                    outline-none py-1 px-3 leading-9
                    text-[#242331]"
                    />
                  </div>
                </div>
                <div className="p-3 w-2/2 lg:w-1/2">
                  <div className="relative">
                    <label
                      for="name"
                      className="leading-8 text-[20px] py-12 font-bold text-[#242331]"
                    >
                      Your Email *
                    </label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      placeholder="JohnDavidQ@gmail.com"
                      className="w-full h-[50px] border-2 border-[#DCDCDC] rounded-xl 
                     focus:border-[#DCDCDC] text-base 
                    outline-none  py-1 px-3 leading-9
                    text-[#242331]"
                    />
                  </div>
                </div>
                <div className="p-3 w-2/2 lg:w-1/2">
                  <div className="relative">
                    <label
                      for="name"
                      className="leading-8 text-[20px] py-12 font-bold text-[#242331]"
                    >
                      Company Name*
                    </label>
                    <input
                      type="text"
                      id="company"
                      name="company"
                      placeholder="your company name"
                      className="w-full h-[50px] bg-gray-[#DCDCDC]  rounded-xl 
                      border-2 border-[#DCDCDC] focus:border-[#DCDCDC] text-base 
                    outline-none  py-1 px-3 leading-9
                    text-[#242331]"
                    />
                  </div>
                </div>
                <div className="p-3 w-2/2 lg:w-1/2">
                  <div className="relative">
                    <label
                      for="name"
                      className="leading-8 text-[20px] py-12 font-bold text-[#242331]"
                    >
                      Subject*
                    </label>
                    <input
                      type="text"
                      id="subject"
                      name="subject"
                      placeholder="how can we help"
                      className="w-full h-[50px] bg-gray-[#DCDCDC]  rounded-xl 
                      border-2 border-[#DCDCDC] focus:border-[#DCDCDC] text-base 
                    outline-none  py-1 px-3 leading-9
                    text-[#242331]"
                    />
                  </div>
                </div>
                <div className="p-3  lg:w-full">
                  <div className="relative">
                    <label
                      for="name"
                      className="leading-8 text-[20px] py-12 font-bold text-[#242331]"
                    >
                      Message Us*
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      placeholder="Hello there, I would like to talk about how to..."
                      className="w-[100%] bg-gray-[#DCDCDC]  rounded-xl  border-2 border-[#DCDCDC]  h-[15rem]  text-[#242331] 
                    text-base outline-none  py-1 px-3 resize-none leading-6 "
                    ></textarea>
                  </div>
                </div>
                <div className="p-3  w-full">
                  <button
                    type="submit"
                    className="rounded-lg bg-[#FFB422] px-6 py-3 w-[10rem] h-11 gap-2.5 leading-5 text-[16px] font-grotesk font-semibold text-black shadow-sm
                   hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 
                   focus-visible:outline-indigo-600"
                  >
                    Send Message
                  </button>
                  <SuccessMessage visible={showSuccessMessage} />
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default ContactView;
