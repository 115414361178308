import React, { useState } from "react";
import { Footer, Header, ScrollToTop, Sidebar } from "../components";
import {
  Approach,
  Barner,
  ServiceCard,
  ServiceLandingPage,
} from "../components/services";

const Services = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div>
     <Header isOpen={isOpen} setOpen={setOpen}/>
      <Sidebar isOpen={isOpen} setOpen={setOpen}/>
      <ServiceLandingPage />
      <ServiceCard />
      <Approach />
      <Barner />
      <ScrollToTop/>
      <Footer />
    </div>
  );
};

export default Services;
