const menu = [
  {
    key: "home",
    title: "Home",
    url: "/",
  },
  {
    key: "about",
    title: "About Us",
    url: "/About",
  },


  {
    key: "services",
    title: "Services",
    url: "/Services",
  },
  {
    key: "contact",
    title: "Contact",
    url: "/Contact",
  }
];

export default menu;
