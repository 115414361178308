import React from "react";
import { IMAGES, WIREFRAMES } from "../../constants";

const Approach = () => {
  const review = [
    {
      text: "Review",
    },
    {
      text: "Identity",
    },
    {
      text: "Recommend",
    },
    {
      text: "Implement",
    },
  ];
  const list = [
    {
      text: "Your business case for a new digital solution",
    },
    {
      text: "Your existing website, app to be revamped",
    },
    {
      text: "The existing software solutions you utilise",
    },
    {
      text: "Your business processes",
    },
    {
      text: "Your social media platforms",
    },
    {
      text: "Your Pay-per-click adverts  accounts",
    },
    {
      text: "Your information storage and sharing architecture",
    },
  ];

  return (
    <section className="text-gray-600 body-font bg-gradient-to-r from-[#F2F2F2] to-[#036EEC]">
      <div className="bg-[#E9F3FE ] py-6 md:py-6 lg:py-24 mx-auto">
      
        
       <div className="flex  justify-center  xs:px-[2rem] md:px-[2rem] lg:px-[2rem]">
            <div>
              <p class=" text-center text-[30px] lg:w-[35rem] max-[950px]:w-[10rem]  lg:text-[47px] font-grotesk  font-bold ">
                {" "}
                Our Approach
              </p>
              <div className="w-auto h-auto  ">
                <p className=" w-[15rem] max-[950px]:w-[10rem] mx-auto">
                <img  src={WIREFRAMES.blueline}  alt=""/> 
            </p>
            <p
            className="text-[20px] text-nuneto   text-sans md:text-[24px] lg:text-[24px]
        mx-auto leading-[38.4px] text-center"
          >
            We Use A{" "}
            <span >
              <i className="text-[#082757]">RIRI</i>
            </span>{" "}
            Method
          </p>
              </div>
            </div>
          </div>
        <div className="flex flex-nowrap md:flex-nowrap  lg:flex-nowrap ">
          <div className=" md:w-[100%] lg:w-1/2 w-full ">
            <div className="h-full flex flex-wrap lg:flex-nowrap lg:flex-inherit lg:flex-row m-3 items-center lg:pl-[450px] lg:max-2xl:pl-[350px]">
              {review.map((items) => (
                <>
                  <div className="flex-grow">
                    <p class="mb-4 px-8 py-8 flex items-center justify-center md:justify-start">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        class="w-6 h-6 text-[#12334C] "
                      >
                        <path
                          fill-rule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <h2 className="text-[15px] md:text-[20px] lg:text-[20px] leading-[32px] font-medium text-center text-[#1D242D] ">
                        {items.text}
                      </h2>
                    </p>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-row">
          <section className="text-gray-600 body-font">
            <div className="mx-auto flex flex-col-reverse py-5 px-5 md:py-20 lg:py-24 md:flex-col justify-center lg:px-28 lg:flex-row items-center">
              <div className="items-unset md:items-baseline lg:items-baseline lg:flex-grow md:w-[80%] lg:pr-60 md:pr-26 flex flex-col  mb-16 md:mb-0 text-center">
                <h1 className=" text-center title-font text-[25px] md:text-[40px] font-grotesk font-bold h-[64px] leading-[63.8px] mb-4  lg:text-[40px] text-[#3292DA]">
                  What we review
                </h1>
                <p className="mb-8 max-w-[380px] leading-[32px] text-[#909DAD] text-[15px] text-center md:text-[20px] lg:text-[20px] font-nuneto  lg:text-start md:max-w-[730px] lg:max-w-[430px]">
                  We meet with you to gather your requirements so as to
                  understand where you are currently where you want to be and
                  what it takes to get there. In particular we gather
                  information relating to the
                  <br /> following:
                </p>
                {list.map((lists) => (
                  <>
                    <ul class="space-y-4 text-left text-gray-500 dark:text-gray-400">
                      <li class="flex  items-center space-x-3 py-4 px-4 gap-y-6 max-w-[100%]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          class="w-6 h-6 text-[#FF6915] "
                        >
                          <path
                            fill-rule="evenodd"
                            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span max-w-full>{lists.text}</span>
                      </li>
                    </ul>
                  </>
                ))}
              </div>
              <div className="lg:max-w-lg lg:w-full items-end ">
                <img
                  className="object-cover object-center rounded"
                  alt="hero"
                  src={IMAGES.reviewImg}
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default Approach;
