import React, { useState } from 'react'
import { AboutPage, Footer, Header, ScrollToTop, Sidebar } from '../components'

const About = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <Header isOpen={isOpen} setOpen={setOpen}/>
      <Sidebar isOpen={isOpen} setOpen={setOpen}/>
     <AboutPage/>
     <ScrollToTop/>
     <Footer/>
    </>
  )
}

export default About