import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../constants";

const Landingpage = () => {
  return (
    <div className="bg-[#082757] mx-auto lg:pt-[8rem] pt-[4rem]">
      <div className="  px-5   flex justify-between max-xsm:flex-col max-midmax:flex-col ">
        <div className="px-5 lg:w-[50%]  pt-[4rem]   lg:pt-[8rem] max-xsm:w-[100%] lg:py-5 md:px-[7rem] lg:px-10 ">
          <div className="lg:mx-auto max-xsm:w-[100%]  max-midmax:w-[100%]">
            <div className="text-center max-midmax:w-[100%]">
              <h1
                className="text-[25px] text-center font-grotesk text-white  md:text-[45px]  md:top-9 
     md:leading-[56.7px] md:tracking-tight  lg:max-xl:px-1 lg:text-[45px]  lg:max-text-start lg:px-[3.5rem] font-bold
    lg:leading-[56.7px] lg:pt-9 px-22 lg:text-start "
              >
                Our forward-thinking digital agency helps you break new ground
              </h1>
              <p
                className="pt-6  leading-8 px-[2.5rem] text-[12px] text-white font-mulish max-w-2xl 
      md:text-[20px] md:px-[4rem] md:lg:max-w-xl md:font-sans md:max-w-3xl
    lg:text-[20px] lg:max-w-xl font-medium lg:font-nuneto mt-6 lg:max-xl:px-1 lg:px-[3.5rem] lg:text-start lg:max-text-start"
              >
                At Ebony Technology, we create digital solutions that support
                businesses to launch, run and grow.
              </p>
              <div className=" mt-8 lg:flex lg:flex-1 px-[3.5rem]  py-10 w-[100%]">
                <Link
                  to="/Contact"
                  className="rounded-lg bg-[#FFB422] px-6 py-3 w-45 h-11 gap-2.5 leading-5 text-[16px] font-grotesk font-semibold text-black shadow-sm
        hover:bg-[#3292DA] hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 
         focus-visible:outline-[#3292DA]"
                >
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div class="flex w-fit-to-content">
          <img
            //           className="w-[60rem]
            //  max-xsm:h-[365px] "
            className="pt-[50px] items-center md:pt-[7rem] md:pl-[6rem] lg:pt-0"
            src={IMAGES.happyGuyImg}
            alt="EbonyTechnology"
          />
        </div>
      </div>
    </div>
  );
};

export default Landingpage;
