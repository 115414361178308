import { ICONS } from "../constants";

const SocialMedia = [
  {
    title: "facebook",
    url: "https://facebook.com/",
    icon: ICONS.facebookIcon,
  },
  {
    title: "Twitter",
    url: "https://twitter.com/",
    icon: ICONS.twitterIcon,
  },
  {
    title: "Instagram",
    url: "https://www.instagram.com/",
    icon: ICONS.instagramIcon,
  },
  {
    title: "LinkedIn",
    url: "https://www.linkedin.com/",
    icon: ICONS.linkedInIcon,
  },
];

export default SocialMedia;
