import React, {useState} from 'react'
import { ContactView, Footer, Header, ScrollToTop, Sidebar } from '../components'

const Contact = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
    <Header isOpen={isOpen} setOpen={setOpen}/>
      <Sidebar isOpen={isOpen} setOpen={setOpen}/>
   <ContactView/>
   <ScrollToTop/>
    <Footer/>
    </>
  )
}

export default Contact