import React from 'react'
import { OfferData } from '../../fixtures';
import { WIREFRAMES } from '../../constants';

const ServiceCard = () => {
  return (
    <section className="px-5 mx-auto py-4 lg:py-20 lg:px-32 lg:max-2xl:px-20 bg-[#F6F8F9]">
    <div className=" absolute hidden  md:top-[145rem] md:left-[2rem] lg:top-[70rem] lg:left-[90rem] 
lg:max-2xl:block lg:block lg:max-2xl:left-[79rem] lg:max-2xl:top-[48rem] ">
  <div className="flex-grow">
              <div className="flex">
    <img src={WIREFRAMES.servicetoptriangle} alt="eclips" className="" />
  </div>
  </div>
  </div>
    <div className="max-w-screen-xl mx-auto px-4  md:px-4 py-4 ">
        <ul className="grid gap-x-8 gap-y-10 mt-16 sm:grid-cols-2 lg:grid-cols-3 lg:[&>*:nth-child(4)]:ml-[50%] lg:[&>*:nth-child(5)]:ml-[200px]">
            {
                OfferData.map((items, key) => (
                    <li className="p-4 w-[300px] lg:w-[400px] mx-auto h-[536px] bg-[#FFFFFF] hover:bg-[#3D4C5E] pt-[8px] 
                   pb-5 bg-auto rounded-[20px] lg:py-9 lg:px-9 group sm:max-w-sm" key={key}>
                        <a href={items.href}>
                            <img src={items.img} loading="lazy" alt={items.title} className="w-full rounded-lg" />
                            <div className="mt-3 space-y-2">
                                <h3 className="text-lg text-gray-800 duration-150 group-hover:text-indigo-600 font-semibold">
                                    {/* {items.title} */}
                                </h3>
                            </div>
                        </a>
                        <div className=' p-2 lg:p-5'>
                        <p class="mb-3 font-sans font-normal text-[#808080] hover:text-[#FFFFFF] text-[13.5px]
                        leading-[25.6px] max-w-[500px] text-center dark:text-gray-400">
                        {items.desc}</p>
                        </div>
                    </li>
                ))
            }
        </ul>
     
    </div>
    
   
</section>
  )
}

export default ServiceCard