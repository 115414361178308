import {RoutesManager} from "./routesManager";
import { BrowserRouter } from "react-router-dom";

const App = () => {
  return (
    <BrowserRouter>
    <RoutesManager />
  </BrowserRouter>
  )
}

export default App