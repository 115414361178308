import { FaqsCard } from "../../components/services";

import { IMAGES, WIREFRAMES } from "../../constants";

const faqsList = [
  {
    q: "Businesses",
    a: "StartUps And Establishment",
  },
  {
    q: "Governmental Agencies",
    a: "Agencies",
  },
  {
    q: "Educational Institutions",
    a: "Schools, Colledges and universities",
  },
  {
    q: "Non Profit Organisations",
    a: "NGO's.",
  },
];

const AboutUs = () => {
  return (
    <section>
      <div className="bg-[#082757] h-[608px] w-[100%] md:h-[670px] lg:h-[600px] lg:max-2xl:h-[550px]">
        <div className="box-border   lg:py-[18rem] pt-[18rem] text-grey-darkest ">
          <div className="flex  justify-center mx-auto ">
            <div>
              <p class="text-white text-center text-[30px] lg:w-[35rem] w-[10rem]  lg:text-[47px] font-grotesk  font-bold ">
                {" "}
                About Us
              </p>
              <div className="w-auto h-auto  ">
                <p className=" lg:w-[15rem] w-[10rem]  mx-auto">
              <img src={WIREFRAMES.yellowline} alt="" />
            </p>
              </div>
            </div>
          </div>

          <div class="position  lg:block max-xsm:hidden md:hidden sm:hidden  ">
            {/* <img
                src={WIREFRAMES.wireframeContact}
                alt=""
                className=" opacity-30 "
              /> */}
          </div>
        </div>
      </div>
      <div className="bg-[#FBF4E7] flex justify-evenly   md:px-8  w-[100%] lg:pt-28 pt-5  max-xsm:flex-col-reverse ">
        <div className=" h-fit-to-content  overflow-hidden rounded-lg    md:h-auto ">
          <img
            src={IMAGES.happyWomanImg}
            loading="lazy"
            alt="About us"
            className=""
          />
        </div>

        <div className="md:pt-8">
          <div
            className=" absolute hidden  md:top-[145rem] md:left-[2rem] lg:top-[41rem] lg:left-[88rem] 
        lg:max-2xl:block lg:block lg:max-2xl:left-[75rem] lg:max-2xl:top-[35rem] "
          >
            <div className="flex-grow">
              <div className="flex">
                <img src={WIREFRAMES.aboutstar} alt="eclips" className="" />
              </div>
            </div>
          </div>
          <h1
            className="mb-4 text-center py-6 text font-grotesk text-2xl font-bold text-gray-800 md:text-3xl 
          lg:text-4xl  md:mb-6 md:text-left"
          >
            About Ebony Technology
          </h1>
          <p className="mb-6 text-[#3D4C5E] font-nuneto leading-loose lg:max-w-[550px]  text-center md:text-start lg:text-start tracking-wider sm:text-lg md:mb-8">
            We are a UK tech company creating digital solutions that support
            businesses and organisations across the world to launch, run and
            grow. We achieve this by working closely with our clients to
            understand, not assume their requirements. We then combine this
            information with business intelligence to turn our clients’ problems
            into solutions.
          </p>
          <p className="py-6 mb-6 text-[#3D4C5E] leading-loose font-nuneto max-w-[550px] text-center md:text-start lg:text-start tracking-wider sm:text-lg md:mb-8">
            Our team comprises business-minded professionals with expertise in
            software engineering, UI/UX design, web and mobile development,
            growth hacking, database system administration, cyber security,
            digital marketing and entrepreneurship.
          </p>
        </div>
      </div>

      <div className="text-base pr-[0.5rem] pl-[0.5rem]  md:pr-[2rem] md:pl-[2rem]  lg:text-xl  lg:pr-[4rem] lg:pl-[8rem] font-grotesk py-[4rem] lg:max-w-[75%] text-gray-600">
        <div className="text-center md:text-start lg:text-start">
          <h3 className="text-gray-800 text-3xl md:text-4xl lg:text-5xl font-semibold px-6">
            Our clients
          </h3>
          <p className="py-6 px-6 max-w-[100%] font-nuneto font-normal leading-[38.4px]">
            Our passion is not just to create digital products but creating the
            kind of products that offer utility to our clients and their
            patrons. Our strength comes from paying attention to what the user
            wants and prioritizing development activities focused on gaining
            traction.
          </p>
          <p className="mt-3 py-4 px-6 max-w-full font-nuneto font-normal leading-[38.4px]">
            Although we are a UK company, we serve clients from around the world
            across different industries. In particular we cater for the
            following categories of clients:
          </p>
        </div>
      </div>

      <section className="text-gray-600 body-font">
        <div className="mx-auto flex px-[2rem] py-12 md:flex-row flex-col items-center lg:px-[9rem] ">
          <div className="w-auto lg:max-w-lg lg:w-full md:w-1/2  mb-10 md:mb-0 bg-[#EBF4FB] space-y-4 ">
            <img className="rounded-xl" alt="hero" src={IMAGES.handshakeImg} />
          </div>
          <div className=" flex-grow lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
            <div className="mt-14 max-w-[450px] mx-auto">
              {faqsList.map((item, idx) => (
                <FaqsCard idx={idx} faqsList={item} />
              ))}
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default AboutUs;
