import React, { useState } from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";

const HomePageTestimony = () => {
  const testimonials = [
    {
      avatar: "https://api.uifaces.co/our-content/donated/xZ4wg2Xj.jpg",
      name: "james Tarif",
      quote:
        "Overall, I highly recommend ebony Technology to any business looking for a Tech consultancy and support provider. Their expertise and commitment to excellence have been essential in helping our business stay ahead of the curve, and I'm grateful for their partnership.",
    },
    {
      avatar: "https://randomuser.me/api/portraits/women/79.jpg",
      name: "Angela stian",
      quote:
        "I wholeheartedly recommend Ebony Technology to any business looking for cutting-edge digital solutions and a partner dedicated to their success. With Ebony Technology by your side, you can confidently navigate the digital age and take your business to new heights. Thank you, Ebony Technology, for your outstanding work and unwavering commitment to excellence. ",
    },
    {
      avatar: "https://randomuser.me/api/portraits/men/86.jpg",
      name: "Karim ahmed",
      quote:
        "One of the aspects that impressed me the most was the level of customization and scalability that Ebony Technology offers. Whether it's developing a user-friendly website, creating a mobile app, or optimizing our digital marketing strategy, they have consistently delivered results that exceeded our expectations.",
    },
  ];

  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentTestimonial === 0;
    const newIndex = isFirstSlide
      ? HomePageTestimony.length
      : currentTestimonial - 1;
    setCurrentTestimonial(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentTestimonial === HomePageTestimony.length + 2;
    const newIndex = isLastSlide ? 0 : currentTestimonial + 1;
    setCurrentTestimonial(newIndex);
  };

  return (
    <section className="py-2 px-8 lg:py-12 lg:px-">
      <div className="max-w-screen-sm md:max-w-screen-md mx-auto px-4 md:px-8 lg:max-w-screen-2xl ">
        <div className="max-w-4xl mx-auto text-center">
          <h3 className="text-[#1D242D] font-bold leading-[44px] font-grotesk text-[40px] text-center pb-6">
            Testimonials
          </h3>
          <h1 className="text-[#1D242D] font-bold leading-[50px] font-grotesk text-[100px] text-center pt-6">
            “
          </h1>

          <div className="flex justify-between">
            {/* Left Arrow */}
            <div className=" lg:block group-hover:block  bg-[#3292DA] lg:[10rem]  h-6 w-6   text-2xl rounded-full p-1 text-white cursor-pointer">
              <BsChevronCompactLeft onClick={prevSlide} size={18} />
              <button onClick={prevSlide} size={18} />
            </div>
            <div>
              <ul>
                {testimonials.map((item, idx) =>
                  currentTestimonial === idx ? (
                    <li key={idx}>
                      <figure>
                        <blockquote>
                          <p className="text-[20px]  text-[#3D4C5E] font-nuneto leading-[25px] lg:leading-[38.4px] text-center lg:text-[24px] ">
                            “{item.quote}“
                          </p>
                        </blockquote>
                        <div className="mt-4 flex flex-col items-center justify-center   lg:mt-8">
                          <div className="mt-3">
                            <img
                              src={item.avatar}
                              alt=""
                              className="w-[151.3px] h-[20%] rounded-full "
                            />
                          </div>
                          <p className="block text-gray-600 font-semibold mt-0.5 ">
                            {item.name}
                          </p>
                        </div>
                      </figure>
                    </li>
                  ) : (
                    ""
                  )
                )}
              </ul>
            </div>
            {/* Right Arrow */}
            <div className=" lg:block group-hover:block bg-[#3292DA] h-6 w-6    text-2xl rounded-full p-1 text-white cursor-pointer">
              <BsChevronCompactRight onClick={nextSlide} size={18} />
              <button onClick={nextSlide} size={18} />
            </div>
          </div>
        </div>
        <div className="mt-2 flex justify-center items-center w-[100%]">
          <ul className="flex gap-x-3 max-w-4xl  px-3 justify-center">
            {testimonials.map((item, idx) => (
              <li key={idx}>
                <button
                  className={`w-2.5 h-2.5 mt-6 rounded-full duration-150 ring-offset-2 ring-indigo-600 focus:ring ${
                    currentTestimonial === idx ? "bg-indigo-600" : "bg-gray-300"
                  }`}
                  onClick={() => setCurrentTestimonial(idx)}
                ></button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default HomePageTestimony;
