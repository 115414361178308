import happyWomanImg from '../assets/images/happyWoman.svg'
import happyGuyImg from '../assets/images/happycopy-removebg-preview.png'
import consultationImg from '../assets/images/consultationImage.svg'
import cyberSecurityImg from '../assets/images/cyberSecImage.svg'
import websiteImg from '../assets/images/webImage.svg';
import digitalImg from '../assets/images/digitalImage.svg'
import marketingImg from '../assets/images/marketingImage.svg';
import implementImg from '../assets/images/implementImg.svg';
import identifyImg from '../assets/images/identifyImg.svg';
import reviewImg from '../assets/images/reviewImg.svg'
import handshakeImg from '../assets/images/handshakeImg.svg'
import chinesegirlImg from '../assets/images/chinesegirlImg.svg'
import frame2 from '../assets/images/Frame2.png'
import frame3 from '../assets/images/Frame3.png'
import frame4 from '../assets/images/Frame4.png'
import frame5 from '../assets/images/Frame5.png'

 const images =  {frame2, frame3, frame4, frame5,happyWomanImg, happyGuyImg, consultationImg,cyberSecurityImg,websiteImg,digitalImg,marketingImg, implementImg,identifyImg,reviewImg, handshakeImg, chinesegirlImg}
 export default images;