import React from "react";
import { IMAGES } from "../../constants";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
const HomePageAboutUs = () => {
  return (
    <div className="bg-[#FBF4E7]   lg:pt-28 md:pt-20 pt-10">
      <div className=" lg:mx-0 mx-auto px-8 md:px-0 lg:px-0">
        <div className="flex justify-between  max-xsm:flex-col-reverse">
          <div>
            <div className="h-fit-content overflow-hidden rounded-lg  md:h-auto">
              <img
                src={IMAGES.happyWomanImg}
                loading="lazy"
                alt="About us"
                className=""
              />
            </div>
          </div>

          <div className="md:pt-8 px-2">
            <div className="float-right
        hidden  md:block">
              <svg
                width="68"
                height="82"
                viewBox="0 0 68 82"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.6">
                  <path
                    d="M45.2231 9.47928C57.2988 20.9204 57.3755 39.5293 45.4532 51.0615L1.38256 9.23688C13.5659 -2.04418 33.1457 -1.96338 45.2231 9.47928Z"
                    fill="#3292DA"
                    stroke="#3292DA"
                    stroke-width="0.672267"
                  />
                  <path
                    d="M21.324 71.703C8.97102 59.9734 8.97102 40.8418 21.324 29.1123L66.1035 71.703C53.8693 83.4325 33.7957 83.4325 21.324 71.703Z"
                    stroke="#3292DA"
                    stroke-width="1.69089"
                    stroke-miterlimit="10"
                  />
                </g>
              </svg>
            </div>

            <h1 className="mb-4 text-center py-6 text font-grotesk text-2xl font-bold text-gray-800 sm:text-3xl md:mb-6 md:text-left">
              About Us
            </h1>

            <p className=" text-center text-[16px] mb-6 text-[#3D4C5E] leading-10 max-w-[500px] lg:text-start tracking-wider md:mb-8">
              We are a UK tech company creating digital solutions that support
              businesses and organisations across the world to launch, run and
              grow. We achieve this by working closely with our clients to
              understand, not assume their requirements. We then combine this
              information with business intelligence to turn our clients’
              problems into solutions.
            </p>
            <div className="flex py-6 text-[#3292DA] text-[14px] text-base font-sans">
              <a href="/About">Learn More</a>
              <ChevronRightIcon className="h-6 w-4  text-blue-500" />
            </div>
          </div>
          <div className="hidden lg:block">
            <svg
              width="183"
              height="440"
              viewBox="0 0 183 440"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.2">
                <path
                  d="M138.053 219.84C138.053 268.06 178.771 307.149 229 307.149C279.229 307.149 319.947 268.06 319.947 219.84C319.947 171.621 279.229 132.531 229 132.531C178.771 132.531 138.053 171.621 138.053 219.84Z"
                  fill="#B2BBC6"
                />
                <path
                  d="M75.5558 219.84C75.5558 138.352 144.582 72.5337 229 72.5337C313.884 72.5337 382.444 138.799 382.444 219.84C382.444 301.329 313.418 367.147 229 367.147C144.582 367.594 75.5558 301.329 75.5558 219.84ZM369.851 219.84C369.851 145.515 306.888 85.0705 229.466 85.0705C152.045 85.0705 89.0814 145.515 89.0814 219.84C89.0813 294.165 152.045 354.61 229.466 354.61C306.422 355.058 369.851 294.613 369.851 219.84Z"
                  fill="#B2BBC6"
                />
                <path
                  d="M-0.000406738 219.84C-0.000401214 98.5028 102.607 -1.49133e-05 229 -9.60952e-06C355.393 -4.30569e-06 458 98.5028 458 219.84C458 341.178 355.393 439.68 229 439.68C103.073 439.68 -0.000412263 341.178 -0.000406738 219.84ZM444.941 219.84C444.941 105.667 347.931 12.5367 229 12.5367C110.069 12.5366 13.0586 105.667 13.0586 219.84C13.0586 334.014 110.069 427.144 229 427.144C347.931 427.144 444.941 334.462 444.941 219.84Z"
                  fill="#B2BBC6"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageAboutUs;
