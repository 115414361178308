import { IMAGES } from "../constants";


const offers = [
    {
        img: IMAGES.websiteImg,
        desc:"It is one thing to build a product, it is another thing to build a product that customers want. At Ebony Technology we work closely with you to implement your ideas of building a functional, secured, viable and user-friendly web application or mobile app.",
        href:"Read More"
        // logo:
    
    },
    {
        img:IMAGES.digitalImg,
        desc:"The digital world is constantly evolving. We leverage our expertise to help you remain competitive through implementing digital solutions that will enable your business meet changing business and marketing requirements."
      
     
    },
    {
        img: IMAGES.marketingImg,
        desc:"Often times, a poor product well marketed will outsell a good product poorly marketed. We help improve your online visibility, brand awareness, search engine optimization, lead generation and other digital marketing metrics to grow your business."
  
       
    },
    {
        img:IMAGES.cyberSecurityImg,
        desc:'Often times, a poor product well marketed will outsell a good product poorly marketed. We help improve your online visibility, brand awareness, search engine optimization, lead generation and other digital marketing metrics to grow your business.'
    },
    {
        img:IMAGES.consultationImg,
        desc:"The role of technology in business is indispensable yet many businesses do not have the type of support they require to thrive. At Ebony Technology, we provide consultancy, support, troubleshooting and ongoing maintenance for businesses."
    }
]

export default offers
