import { useRef, useState } from "react"

const Faq = (props) => {
    
    const answerElRef = useRef()
    const [state, setState] = useState(false);
    const [answerH, setAnswerH] = useState('0px');
    const { faqsList, idx } = props

    const handleOpenAnswer = () => {
        const answerElH = answerElRef.current.childNodes[0].offsetHeight
        setState(!state)
        setAnswerH(`${answerElH + 20}px`)
    }
  return (
    <div 
    className="space-y-3 mt-5 overflow-hidden"
    key={idx}
    onClick={handleOpenAnswer}
>
    <div className="flex justify-between w-full cursor-pointer rounded-2xl text-white px-3 bg-[#12334C] font-grotesk text-[16px]  py-2 items-center   font-medium">
        <h4> {faqsList.q}</h4>
        <p> {
            state && (
                (
                    <svg className="h-10 w-10 white ml-2" viewBox="0 0 24 24"  fill="white" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                )
       
            ) 
        }</p>
    </div>

    <div
        ref={answerElRef} className="duration-300"
        style={state ? {height: answerH } : {height: '0px'}}
    >
        <div className="px-3 ">
            <p className="text-gray-500">
                {faqsList.a}
            </p>
        </div>
    </div>
</div>
  )
}

export default Faq