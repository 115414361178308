import React from "react";
import { Routes, Route } from "react-router-dom";
import { About, Contact, Home, Loading, Services } from "../pages";

const RoutesManager = () => {
  return (
    <Routes>
   
      <Route path="/" element={<Loading />} />
      <Route path="/home" element={<Home />} />
      <Route path="/About" element={<About />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/Services" element={<Services/>} />
    </Routes>
  );
};

export default RoutesManager;
