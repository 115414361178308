import React, { useState } from 'react'
import { FaqsCard } from '.';
import { IMAGES, WIREFRAMES } from '../../constants';



const Barner = () => {
    const [activeIndex, setActiveIndex] = useState(null);

	const handleClick = (index) => {
		setActiveIndex(index === activeIndex ? null : index);
	};

    const BannerList = [
        {
        text:'Requirements for developing your digital solution'
        },
        {
            text:'Risks and vulnerabilities in your digital solutions'
        },
        {
            text:'Your business processes that can be automated'
        },
        {
            text:'Cost saving measures achievable through technology'
        },
        {
            text:'Growth hacking and productivity techniques suitable for your business'
        }
    ]
    const recommend = [
        {
            frame: IMAGES.frame2,
            text: 'Proof of concept, wireframe, prototype or agile plan'
    
        },
        {
            frame: IMAGES.frame3,
            text: 'Cost estimate'
    
        },
        {
            frame: IMAGES.frame4,
            text: 'Time estimate'
        },
        {
            frame: IMAGES.frame5,
            text: 'Resources required'
        }
    
    ]
    const faqsList = [
      {
          q: "Website",
          a: "Websites are built with respect to the specification and delivered on time"
      },
      {
          q: "Web application",
          a: "Enterprice Application"
      },
      {
          q: "Mobile app",
          a: "Yes! there are two ways that you can use this question generator depending on what you're after. You can indicate that you want 21 questions generated."
      },
      {
          q: "Onboarding your business ",
          a: "Email marketing"
      },
      {
          q: "Digital marketing solutions",
          a: "If you've been searching for a way to get random questions, you've landed on the correct webpage. We created the Random Question Generator to ask you as many random questions as your heart desires."
      },
      {
        q: "Technical support plan",
        a: "Business process automation"
    },
    ]
  return (
    <section className="text-gray-600 px-5 md:px-14 lg:px-[10rem] py-5">
    <div className="relative overflow-hidden rounded-lg  cursor-pointer">
      <img className=" w-[100%] h-[220px] lg:h-[317px]  "  src={IMAGES.identifyImg} alt="Flower and sky"/>
  
    </div>
    <ul class="max-w-7xl space-y-1 px-2 pb-6 lg:px-[20rem] text-gray-500 list-inside dark:text-gray-400">
     {
      BannerList.map((banner, key) => (
      <li class="flex items-center " key={key}>
        <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 9.5C13.3807 9.5 14.5 10.6193 14.5 12C14.5 13.3807 13.3807 14.5 12 14.5C10.6193 14.5 9.5 13.3807 9.5 12C9.5 10.6193 10.6193 9.5 12 9.5Z" fill="#000000"></path> </g></svg>
         {banner.text}
      </li>
      ))
      }
   
    </ul>
    <div className="flex flex-row">
            <section className="text-gray-600 body-font">
              <div className="mx-auto flex px-1 py-2 md:py-24 lg:py-24 md:flex-row justify-center lg:px-8 flex-col items-center">
                <div className="lg:flex-grow md:w-1/2  md:pr-16 flex flex-col md:items-start md:text-left  md:mb-0 items-center text-center">
                  <h1 className="leading-[25px] max-w-[15rem] text-[25px] title-font md:text-[50px] lg:text-[50px] font-grotesk 
                  font-bold h-[64px] lg:leading-[45.3px]  md:leading-[45.3px] mb-4 lg:max-w-xs  text-[#007AFF]">What we recommend</h1>
                      <p className="mb-8 leading-[25.6px] max-w-2xl text-[#546881] text-[16px] py-6  font-nuneto">We recommend a plan and agree a course of action that meets your 
                                 need and fits within your budget. This will cover such areas as:</p> 
                                 <img src={WIREFRAMES.servicearrow} alt='' className='hidden md:block lg:block pl-12 md:pl-20 lg:pl-[18rem]' />
                      
                    </div>
                    <div className="items-start lg:flex-grow md:w-1/2 md:pr-16 flex flex-col 
                    md:items-start md:text-left mb-16 md:mb-0 text-center">
                             { 
                             recommend.map((lists, key) => (
                              <>
                              <ul key={key} class="space-y-4 text-left text-gray-500 dark:text-gray-400">
                              <li class="flex items-center  py-4 px-4 max-w-sm gap-y-6 ">
                               <img className='w-[60px] h-[60px]' src = {lists.frame} alt=''/>
                                  <span className='max-w-full' >{lists.text}</span>
                              </li>
                         </ul>
                         </>
                             ))     
             
                             }
                    </div>
                  </div>
                </section>
            </div>
  
      <section className="text-gray-600 body-font">
    <div className=" mx-auto flex px-5 py-24 md:py-10 md:flex-row flex-col items-center">
      <div className="lg:max-w-lg lg:w-[523px] md:w-1/2 mb-10 md:mb-0 ">
        <img className="object-cover object-center rounded" alt="hero" src={IMAGES.implementImg}/>
        {/* <img className="absolute top-[350rem] " alt="hero" src={Bg}/> */}
      </div>
      <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
        <h1 className=" text-[#3292DA] leading-[25.3px] text-[20px] md:text-[50px] lg:text-[50px] font-grotesk md:leading-[45.3px] lg:leading-[45.3px] ">What we <br/>implement </h1>
        <p className="mb-8 leading-[25.6px] max-w-md text-[16px] font-nuneto py-6 ">Lastly, we implement solutions focussed on optimising productivity and growth. These include:</p>
        <div className="mt-14 max-w-[450px] mx-auto">
                  {
                      faqsList.map((item, idx) => (
                          <FaqsCard
                              idx={idx}
                              faqsList={item}
                          />
                      
                      ))
                  }
              </div>
      </div>
    </div>
  </section>
    </section>
  )
}

export default Barner   