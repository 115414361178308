import React from "react";

const HomePageFeature = () => {
  return (
    <div className="bg-[#FDFCF8]">
      <div className="max-[750px]:block max-[900px]:hidden pl-[32px] pt-[39px] lg:block">
        <svg
          width="61"
          height="81"
          viewBox="0 0 61 81"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M40.6 8.73075C51.8 20.3717 51.8 39.359 40.6 51L0 8.73075C11.2 -2.91025 29.4 -2.91025 40.6 8.73075Z"
            fill="#BFDDF4"
          />
          <path
            d="M18.4 70.2693C7.2 58.6283 7.2 39.641 18.4 28L59 70.2693C47.9077 81.9103 29.7077 81.9103 18.4 70.2693Z"
            stroke="#BFDDF4"
            stroke-width="2.5152"
            stroke-miterlimit="10"
          />
        </svg>
      </div>

      <section className="pt-10 lg:py-14 px-32 lg:px-32 lg:max-xl:px-2 md:py-24 py-24 flex md:flex-row md:justify-between flex-col justify-center items-center mx-auto">
        <div>
          <p className=" text-[#1D242D] text-2xl  w-[300px]  font-bold font-grotesk max-w-sm md:text-3xl md:text-[18px] lg:text-3xl lg:text-[24px] ">
            You want to boost your business growth?
          </p>
          <p className="text-[#1D242D] font-nuneto w-[300px]  text-[24px] py-8  md:text-[14px] lg:text-[24px]  ">
            worry less solution is here
          </p>
        </div>
        <div>
          <p
            className="text-[#546881] text-center lg:pt-5 w-[300px] text-xl lg:w-[501px]  max-w-2xl font-grotesk tracking-wide leading-7 md:max-w-md md:text-[14px]
            lg:text-[20px] lg:text-start lg:max-w-2xl"
          >
            Our passion is not just to create digital products but creating the
            kind of products that offer utility to our clients and their
            patrons.{" "}
          </p>
        </div>
      </section>
    </div>
  );
};

export default HomePageFeature;
