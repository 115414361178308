import React from 'react'
import { Cards } from '../global'
import { HomePageFeature } from '../components/homepage'

const HomeCardContainer = () => {
  return (
    <div>
      
   

       <HomePageFeature/>
        <Cards/>
        
    </div>
  )
}

export default HomeCardContainer