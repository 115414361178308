import React from 'react';
import { OfferData } from '../fixtures';
// import { ChevronRightIcon } from '@heroicons/react/24/outline';


const cards = () => {
  return (
    <section className="py-2 px-2 bg-[#FDFCF8] lg:py-2 lg:px-32">
    <div className="max-w-screen-xl mx-auto px-4 md:px-4  lg:px-8">
        <ul className="grid grid-cols-1 md:grid-cols-2 gap-[44px] max-[950px]:mb-5 lg:mb-10  mt-2 lg:grid-cols-3  lg:[&>*:nth-child(4)]:ml-[50%] lg:[&>*:nth-child(5)]:ml-[200px]">
            {
                OfferData && OfferData.map((items, key) => (
                    <li className=" w-[100%] lg:w-full mx-auto group sm:max-w-sm" key={key}>
                        <a href={items.href}>
                            <img src={items.img} loading="lazy" alt={items.title} className="w-full rounded-lg" />
                            <div className="mt-3 space-y-2">
                                <h3 className="text-lg text-gray-800 duration-150 group-hover:text-indigo-600 font-semibold">
                                    {items.title}
                                </h3>
                            </div>
                        </a>
                    </li>
                ))
            }
        </ul>
    </div>
    {/* <div className='flex px-14 text-[#3292DA] text-[14px] text-base font-sans'><a href='/Services'>Read More</a> 
       <ChevronRightIcon className='h-6 w-4  text-blue-500'/>
    </div> */}
</section>
  )
}

export default cards