import React from "react";
import { ContactDetails } from "../../fixtures";
import { ICONS } from "../../constants";

const Footer = () => {
  return (
    <div className=" w-full  bg-[#082757] ">
      <div className="flex  justify-between max-[950px]:px-[24px]  max-xsm:px-[24px] max-xsm:pt-[89px] max-xsm:pb-[52px] max-xsm:flex-col   px-2 text-white  lg:py-[58px] lg:pb-20 lg:pl-[100px] lg:pr-[100px]  max-midmax:p-[46px] ">
        <div>
          {/*   mobile view */}

          <div className="max-xsm:flex  hidden max-xsm:justify-between w-[100%] mt-[3%] text-[14px] ">
            <div className="w-[30%]">
              <p className="mb-2">Home</p>
              <p>About</p>
              <p className="w-[60%] max-xsm:mt-3">
                <img src={ICONS.ebonylogo} alt="" />
              </p>
            </div>
            <div>
              <p className="mb-2 w-[70%]">Contact Us</p>
              <p>Services</p>
              <p className="text-[14px] font-bold max-xsm:mt-3  text-white leading-9 font-grotesk ">
                We create digital solutions that support businesses to launch,
                run and grow.
              </p>
            </div>
          </div>

          {/* mobile ends here */}

          <p className="w-[61px] max-x:bg-sky-300 max-xsm:hidden">
            <img src={ICONS.ebonylogo} alt="" className="w-[100%]" />
          </p>
          <p className="text-[14px] font-bold  text-white leading-9 font-grotesk max-w-[360px] max-xsm:hidden max-midmax:w-[294px]">
            We create digital solutions that support businesses to launch, run
            and grow.
          </p>
          <p className="text-[14px] font-[400]  text-white pt-12 font-grotesk max-midmax:w-[231px]    max-xsm-[261px]" >
            25 Courtney Road, Grays, Essex, UK RM16 4TZ <br /> +44 (0) 772 761
            8504
          </p>

          <p className="text-[12px] font-[400] lg:block max-xsm:hidden max-midmax:block hidden text-white leading-9 pt-9 font-grotesk max-w-[460px] max-midmax:w-[190px]">
            © {new Date().getFullYear()} Ebony Technology. Copyright and rights
            reserved
          </p>
        </div>

        {/*  tablet  */}
        <div className=" max-midmax:block hidden">
          <div className="flex justify-between w-[12%] mt-[3%] text-[14px] max-xsm:hidden max-midmax:w-[42%] max-midmax:mb-[133px]">
            <div>
              <p className="mb-2 ">Home</p>
              <p>About</p>
            </div>
            <div>
              <p className="mb-2">Contact Us</p>
              <p>Services</p>
            </div>
          </div>
          <div className="mt-[3%]">
            <p className="lg:w-[378px] md:w-[300px] max-[650]:w-[60%]">
              We will like to send you newsletters about our products, offers
              and services.
            </p>
            <div>
              <form className="form flex h-[50px] w-[308px] max-[650px]:w-[80%] lg:w-[378px] pt-10  items-center justify-center ">
                <input
                  name="Email"
                  type="Email"
                  placeholder="Enter email address"
                  className="text-gray-500 max-[650px]:text-[8px]  w-full py-[17px] max-[650px]:py-[10px] rounded-l-lg max-[650px]:pl-[10px]  pl-[20px] "
                />
                <button
                  className="p-2 px-3 py-[17px] max-[650px]:pl-[10px] max-[650px]:text-[8px]    pl-[20px] max-[650px]:py-[10px]  font-medium  text-white bg-[#3292DA] hover:bg-blue-500 rounded-r-lg active:bg-blue-700
 duration-150 outline-none shadow-md focus:shadow-none sm:px-4"
                >
                  Subscribe
                </button>
              </form>
            </div>
            <div className="  mt-[20%]   ">
              <div className="w-full flex  gap-2">
                {ContactDetails.map((item, idx) => (
                  <div key={idx}>
                    <img
                      src={item.icon}
                      alt=""
                      className="w-[29px]  mr-[16px] "
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-between max-midmax:hidden  w-[12%] mt-[3%] text-[14px] max-xsm:hidden max-[950px]:text-sm">
          <div>
            <p className="mb-2">Home</p>
            <p>About</p>
          </div>
          <div>
            <p className="mb-2">Contact Us</p>
            <p>Services</p>
          </div>
        </div>
        <div className="mt-[3%] max-xsm:mt-[48px] max-midmax:hidden ">
          <p className="w-[378px] max-xsm:w-[100%] max-[950px]:text-sm">
            We will like to send you newsletters about our products, offers and
            services.
          </p>
          <div>
            <form className="form flex h-[50px] lgw-[308px] max-xsm:w-[100%] lg:w-[378px] pt-14   items-center justify-center sm:max-w-md sm:mx-auto lg:mx-0">
              <input
                name="Email"
                type="Email"
                placeholder="Enter email address"
                className="text-gray-500 w-full py-[17px] rounded-l-lg  pl-[20px] "
              />
              <button
                className="p-2 px-3 py-[17px]  pl-[20px]  font-medium  text-white bg-[#3292DA] hover:bg-blue-500 rounded-r-lg active:bg-blue-700
 duration-150 outline-none shadow-md focus:shadow-none sm:px-4"
              >
                Subscribe
              </button>
            </form>
          </div>
          <div className="float-right  lg:mt-[30%] max-xsm:mt-[10%] max-xsm:float-none max-midmax:mt-[10px] max-midmax:float-none max-max-xsm:mt-[]">
            <div className="w-full flex  gap-2">
              {ContactDetails.map((item, idx) => (
                <div key={idx}>
                  <img
                    src={item.icon}
                    alt=""
                    className="w-[32px] lg:ml-[16px] max-xsm:mr-[16px]"
                  />
                </div>
              ))}
            </div>
          </div>

        </div>
        
        <p className="text-[12px]  font-[400]  max-xsm:block  max-xsm:mt-[3%] text-white leading-9 pt-9 font-grotesk hidden ">
            © {new Date().getFullYear()} Ebony Technology. Copyright and rights
            reserved
          </p>
      </div>
    </div>
  );
};

export default Footer;
