import React, {useState} from "react";
import {
  HomePageAboutUs,
  HomePageTestimony,
  LandingPage,
} from "../components/homepage";
import { Footer, Header, ScrollToTop, Sidebar } from "../components";
import HomeCardContainer from "../containers/HomeCardContainer";


const Home = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <Header isOpen={isOpen} setOpen={setOpen}/>
      <Sidebar isOpen={isOpen} setOpen={setOpen}/>
      <LandingPage />
      <HomeCardContainer />
      <HomePageAboutUs />
      <HomePageTestimony />
      <ScrollToTop/>
      <Footer />
    </>
  );
};

export default Home;
