import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import { MenuItems } from "../../fixtures";

const Index = ({ isOpen, setOpen }) => {
  return (
    <>
      <>
        <AnimatePresence initial={false}>
          {isOpen && (
            <>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { type: "just" } }}
                exit={{
                  opacity: 0,
                  transition: { type: "just", delay: 0.1 },
                }}
                className="fixed top-0 left-0 right-0 bottom-0 bg-[#545f7d80]"
                onClick={() => setOpen(false)}
              />
              <motion.section
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1, transition: { type: "just" } }}
                exit={{
                  x: -100,
                  opacity: 0,
                  transition: { type: "just", delay: 0.1 },
                }}
                className="w-[284px] fixed z-40 left-0 overflow-scroll top-0 h-screen bg-white py-10 navigation-shadow "
              >
             
                <nav className="overflow-y-auto pl-5 h-screen  transition-all ease-in scrollbar-thin hover:scrollbar-thumb-primary hover:scrollbar-track-gray-200 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                  <div className="flex items-center justify-between">
                    <div className="mt-6 ">
                      <div className="-my-6 ">
                        <div className="space-y-2 py-6">
                          {MenuItems &&
                            MenuItems.map((item) => (
                              <Link
                                key={item.key}
                                to={item.url}
                                className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-[#000000] hover:bg-gray-50 font-Grotesk"
                              >
                                {item.title}
                              </Link>
                            ))}
                        </div>
                        <div className="py-6">
                          <Link
                            to="/Contact"
                            className="rounded-lg bg-[#FFB422] px-6 py-3 w-36 h-11 gap-2.5 leading-5 text-[16px] font-['Grotesk'] font-semibold text-black shadow-sm
            hover:bg-[#3292DA] hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 
            focus-visible:outline-[#3292DA]"
                          >
                            Get started
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="m-0 p-4 ">
                      <button type="button" onClick={() => setOpen(false)}>
                        <p
                          className=" w-6  absolute top-[1.5rem] right-4"
                          aria-hidden="true"
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                              stroke="#212121"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9.16992 14.83L14.8299 9.17004"
                              stroke="#212121"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M14.8299 14.83L9.16992 9.17004"
                              stroke="#212121"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </p>
                      </button>
                    </div>
                  </div>
                </nav>
              </motion.section>
            </>
          )}
        </AnimatePresence>
      </>
    </>
  );
};

export default Index;
