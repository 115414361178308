import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ICONS } from "../../constants";
import { Twirl as Hamburger } from "hamburger-react";

import { MenuItems } from "../../fixtures";

const Header = ({ isOpen, setOpen }) => {
  let e = useLocation();

  return (
    <>
      <header className="fixed top-0  w-full max-[550px]:relative flex  bg-[#082757] justify-between py-6 items-center  leading-[1.914rem] max-[950px]:px-6 font-normal md:font-bold  lg:pl-[95px] lg:pr-[95px]  ">
        <div className=" ">
          <a href="/" className=" font-grotesk">
            <span className="sr-only">EbonyTechnology</span>
            <Link to="/">
              <img
                className="h-16 w-auto"
                src={ICONS.ebonylogo}
                alt="EbonyTechnology"
              />
            </Link>
          </a>
        </div>
        <div className="hidden lg:block">
          <div className="hidden lg:flex lg:gap-x-10">
            {MenuItems &&
              MenuItems.map((item) => (
                <Link
                  key={item.key}
                  to={item.url}
                  className="text-[20px] font-medium leading-[25.52px] text-white hover:text-[#FFB422]  font-grotesk"
                >
                  {" "}
                  {item.title}
                </Link>
              ))}
          </div>
        </div>
        {e.pathname === "/Contact" ? (
          <div className=" ">
            <p className=""></p>
          </div>
        ) : (
          <div className="hidden lg:block">
            <a
              href="Contact"
              className="rounded-xl bg-[#FFB422] px-[1.5rem] py-[0.75rem] w-38 h-11 lg:w-[38] lg:h-[44px] gap-2.5 leading-5 text-[16px] font-grotesk font-semibold text-black shadow-sm
             hover:bg-[#3292DA] hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 
             focus-visible:outline-[#3292DA]"
            >
              Get in Touch
            </a>
          </div>
        )}

        <div className="lg:hidden ">
          <Hamburger
            color="#FFFFFF"
            size={20}
            label="Show menu"
            toggled={isOpen}
            toggle={setOpen}
          />
        </div>
      </header>
    </>
  );
};

export default Header;
