import React from 'react'
import { IMAGES, WIREFRAMES } from '../../constants'

const ServiceLanding = () => {
  return (
    <div className="bg-[#082757] mx-auto pt-[12rem] md:pt-[14px] lg:pt-[17rem] text-grey-darkest ">
    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3  gap-4">
       <div className=" lg:pt-0">
         <img src={WIREFRAMES.servicewireframe} alt='' className=' absolute w-[40%] lg:w-[auto]  ' />
       
       </div>
  

       <div className="flex  justify-center  xs:px-[2rem] md:px-[12rem] lg:px-[2rem]">
            <div>
              <p class="text-white text-center text-[30px] lg:w-[35rem] max-[950px]:w-[10rem]   lg:text-[47px] font-grotesk  font-bold ">
                {" "}
                Our Services
              </p>
              <div className="w-auto h-auto  ">
                <p className="md:w-[15rem] w-[10rem] mx-auto">
              <img src={WIREFRAMES.yellowline} alt="" />
            </p>
            <p className=" text-[#F9FAFB] text-[15px] md:text-[20px] lg:text-[20px] font-grotesk capitalize
       font-[400]   text-center mt-6 pt-8 container max-[650px]:w-[26rem] md:max-w-[56rem] lg:max-w-[56rem]  ">
       As a  value driven digital agency , we offer the following services.
      </p>
              </div>
            </div>
          </div>
            <div class="flex w-fit-to-content">
                <img src={IMAGES.chinesegirlImg} alt='' className='md:pt-[50px] items-center pt-[7rem] pl-[6rem] md:pl-0 lg:pl-0 lg:pt-0'  />
                {/* <div className=''>
                    <img src={Linered} />
                    <img src={Lineblue} />
                </div> */}
               
            </div>
            </div>

</div>
  )
}

export default ServiceLanding